<template>
	<multiselect
		:class="{ asterisk: essential }"
		v-model="selectedValue"
		label="value"
		track-by="code"
		:options="options"
		:multiple="true"
		:close-on-select="false"
		:clear-on-select="false"
		placeholder="선택하세요."
		:searchable="false"
		select-label=""
		selectedLabel=""
		deselectLabel=""
		mode="CheckBox"
	>
		<span class="checkbox-label" slot="option" slot-scope="scope">
			{{ scope.option.value }}
		</span>
	</multiselect>
</template>

<script>
export default {
	name: 'MultiSelectBox',
	props: {
		essential: {
			type: Boolean,
			require: false,
		},
		value: {
			type: Array,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
	},
	mounted() {
		this.selectedValue = this.value;
	},
	data() {
		return {
			selectedValue: [],
		};
	},
	watch: {
		selectedValue() {
			this.$emit('input', this.selectedValue);
		},
	},
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__placeholder {
	max-height: 1px;
}
.multiselect__select {
	visibility: hidden !important;
}
.multiselect__tag-icon {
	line-height: 19px !important;
}
.multiselect__tag {
	margin-bottom: 0px !important;
	background: #1fbcc5 !important;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
	background: #1a949b !important;
}
.multiselect__option--highlight {
	background: #1fbcc5 !important;
}
.multiselect__option--highlight:after {
	background: #1fbcc5 !important;
}
.multiselect__tags input {
	min-width: auto !important;
}
</style>
