import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';

import router from '@/router';
import store from '@/store/index';

import global from '@/plugins/global';
// register the plugin on vue
import Toasted from 'vue-toasted';
import Fragment from 'vue-fragment';

Vue.use(Fragment.Plugin);
// or you can use it with require

Vue.use(Toasted, {
	position: 'top-center',
	duration: 2000,
});
Vue.use(global);

Vue.config.productionTip = false;

new Vue({
	render: (h) => h(App),
	router,
	store,
}).$mount('#app');

/**
 * vue-js-modal 라이브러리 버그 !!
 *
 * vue-js-modal 내부 에서 스크롤이생기는 경우
 * 화면이 사라지는 버그가 있음
 *
 * .vm--modal 에 overflow: hidden 속성을 override 하여 제거하면 해결이 되나, 라이브러리를 제거하는것이 좋을듯
 *
 */
let styleTag = document.createElement('style');
styleTag.setAttribute('type', 'text/css');
styleTag.textContent = `.vm--modal {overflow: visible !important;}`;
document.getElementsByTagName('head')[0].appendChild(styleTag);
