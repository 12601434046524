<template>
	<div class="com_layer_toast caution" style="width: 800px; height: 80px">
		<div class="lay_header">
			<h1>{{ toastText }}</h1>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		toastText: {
			type: String,
		},
	},
};
</script>

<style></style>
