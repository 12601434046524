import { order } from './index';

export const searchOrders = (data) => {
	return order.get(`/api/orders`, {
		params: data,
	});
};
export const getContractInfoByCode = (code) => {
	return order.get(`/api/doms/contract/${code}`);
};

export const getOrderInfo = (id) => {
	return order.get(`/api/order/${id}`);
};

export const getNewOrderModel = (data) => {
	return order.get(`/api/order/fresh`, {
		params: data,
	});
};

export const createNewOrderInfo = (data) => {
	return order.post(`/api/doms/public/order`, data, {
		timeout: 5000,
	});
};

export const applyNewOrderInfo = (data) => {
	order.put(`/api/doms/public/order/apply/${data.id}`);
};

// todo : api check
export const sendNewOrderEstimate = (data) => {
	return order.post(`/api/doms/public/order/estimate`, data);
};

export const confirmAdminId = (data) => {
	return order.get(`/api/paysystem/membership/checkadmin`, { params: data });
};

export const getMembershipPaymentDetail = (membershipId) => {
	return order.get(`/api/paysystem/membership/payment/${membershipId}`);
};

export const getMembershipBaseChannelChanged = async (data) => {
	return await order.get(`/api/paysystem/membership/${data.id}/base-channel/${data.baseChannelCode}`);
};

export const getConversionMembership = async (data) => {
	return await order.get(
		`/api/paysystem/membership/conversion/${data.membershipId}/base-channel/${data.baseChannelId}`,
	);
};

export const calculateItemPrice = (data) => {
	return order.post(`/api/doms/public/order/calculate/item-price`, data);
};
