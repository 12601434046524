import modules from '../custom/modules';

const install = (Vue) => {
	if (install.installed) return;

	install.installed = true;

	Object.defineProperties(Vue.prototype, {
		$ms: {
			get() {
				return modules;
			},
		},
	});
};

export default install;
