import Vue from 'vue';

export const show = (component, modalData, size) => {
	new Vue({
		created() {
			this.$modal.show(
				component,
				{
					info: modalData,
				},
				{
					width: size.width,
					height: size.height,
					draggable: true,
				},
			);
		},
	});
};
