export default Object.freeze({
	ADDRESS_CONFIRM_KEY: 'devU01TX0FVVEgyMDIwMTAxOTEzNDYyOTExMDMwMTc=',
	ADDRESS_SEARCH_URL: 'http://www.juso.go.kr/addrlink/addrLinkApi.do',
	COMPANY_NAME: '다우기술',
	FORM_MAIL: 'mail',
	FORM_MESSENGER: 'messenger',
	FORM_BIZMSG: 'bizmsg',
	FORM_TERMS: 'terms',
	FORM_HISTORY: 'history',
	SERVICE_NAME: 'DOMS',
	ACCOUNTING: '경리회계',
	ATT: '근태/휴가',
	BIZPLAY: '경비처리(무증빙)',
	TMSE_S: 'TMSe 장비일체형 S',
	TMSE_E: 'TMSe 장비일체형 E',
	TMSE_SHARE: 'TMSe 장비일체형 T',
	ESS: '경영지원',
	MAX_USER_COUNT: 99999,
	GOVERMENT_SUPPORT_BASE_CHANNEL_CODES: ['BASE-1H820OSYT20Q8', 'BASE-1IAUWJG3YWHTE', 'BASE-URSEAW8VBCC4'],
	chargeType: {
		PAY: 'PAY',
		FREE: 'FREE',
		FREE_MONTHLY: 'FREE_MONTHLY',
	},
	serviceTypeDisplay: {
		SAAS: 'SaaS',
		VPC: 'VPC',
		ON_PREMISE: 'On-Premise',
		'SAAS-FREE': 'Saas-Free',
	},
	SEND_STATUS: [
		{
			name: '성공',
			value: 'SUCCESS',
		},
		{
			name: '전송중',
			value: 'PROCEEDING',
		},
		{
			name: '실패',
			value: 'FAIL',
		},
	],
	BIZMSG: [
		{
			name: '알림톡',
			value: 'ALIMTALK',
		},
		{
			name: '알림톡(SMS)',
			value: 'ALIMTALK_SMS',
		},
		{
			name: 'SMS',
			value: 'SMS',
		},
	],
	PRODUCT_TYPE: [
		{
			name: '전체',
			value: 'all',
		},
		{
			name: '클라우드 공유형(Saas)',
			value: 'DO_SAAS',
		},
		{
			name: '클라우드 단독형(VPC)',
			value: 'DO_VPC',
		},
		{
			name: '고객사 설치형(OnPremise)',
			value: 'DO_ON_PREMISE',
		},
	],
	FORM_TYPE: [
		{
			name: '전체',
			value: 'all',
		},
		{
			name: '폼(1)',
			value: 'FORM1',
		},
		{
			name: '폼(2)',
			value: 'FORM2',
		},
		{
			name: '폼(3)',
			value: 'FORM3',
		},
	],
	TERMS_TYPE: [
		{
			name: '전체',
			value: 'all',
		},
		{
			name: '이용약관',
			value: 'TERMS_OF_USE',
		},
		{
			name: '위치기반',
			value: 'TERMS_OF_LOCATION',
		},
		{
			name: '개인정보',
			value: 'TERMS_OF_PRIVACY',
		},
		{
			name: '마케팅',
			value: 'TERMS_OF_MARKETING',
		},
	],
	CUSTOMER_STATUS: [
		{
			name: '사용중',
			value: 'USING',
		},
		{
			name: '초과사용중',
			value: 'IDLE',
		},
		{
			name: '종료',
			value: 'END',
		},
	],
	CUSTOMER_GROUPWARE_TYPE: [
		{
			name: '클라우드 공유형(Saas)',
			value: 'SAAS',
		},
		{
			name: '클라우드 단독형(VPC)',
			value: 'VPC',
		},
		{
			name: '고객사 설치형(OnPremise)',
			value: 'ON_PREMISE',
		},
		{
			name: '고객사 구축형(OffPremise)',
			value: 'OFF_PREMISE',
		},
	],
	USAGE_STATUS: [
		{
			name: '사용',
			value: 'TRUE',
		},
		{
			name: '사용안함',
			value: 'FALSE',
		},
	],
	MANAGER_TYPE: [
		{
			name: '운영',
			value: 'OPERATION',
		},
		{
			name: '결제',
			value: 'PAYMENT',
		},
		{
			name: '엔지니어',
			value: 'ENGINEER',
		},
		{
			name: '계약',
			value: 'ORDER',
		},
	],
	INDUSTRY_CATEGORY: [
		{
			name: 'ITㆍ웹ㆍ통신',
			value: 'ITㆍ웹ㆍ통신',
		},
		{
			name: '금융ㆍ저축ㆍ보험',
			value: '금융ㆍ저축ㆍ보험',
		},
		{
			name: '건설',
			value: '건설',
		},
		{
			name: '교육',
			value: '교육',
		},
		{
			name: '문화ㆍ예술ㆍ서비스',
			value: '문화ㆍ예술ㆍ서비스',
		},
		{
			name: '미디어',
			value: '미디어',
		},
		{
			name: '의료ㆍ제약ㆍ복지',
			value: '의료ㆍ제약ㆍ복지',
		},
		{
			name: '제조ㆍ화학',
			value: '제조ㆍ화학',
		},
		{
			name: '판매ㆍ유통',
			value: '판매ㆍ유통',
		},
		{
			name: '협회ㆍ단체ㆍ종교',
			value: '협회ㆍ단체ㆍ종교',
		},
	], // 산업분류
	BUSINESS_CONDITION: [
		{
			name: '제조업',
			value: 'MANUFACTURING',
		},
		{
			name: '숙박업',
			value: 'LODGING',
		},
	], // 업태
	BUSINESS_TYPE: [
		{
			name: '한식',
			value: 'LODGING_KOREAN',
		},
	], // 종목
	ORDER_TYPE: [
		{
			name: '신규',
			value: 'FRESH',
		},
		{
			name: '추가',
			value: 'ADD',
		},
		{
			name: '연장',
			value: 'EXTENSION',
		},
		{
			name: '연장추가',
			value: 'ADD_EXTENSION',
		},
		{
			name: '변경',
			value: 'CHANGE',
		},
	], // 주문종류
	PAY_TYPE: [
		{
			name: '무통장입금',
			value: 'TRANSPORT',
		},
		{
			name: '신용카드',
			value: 'CREDITCARD',
		},
		{
			name: '실시간계좌이체',
			value: 'REALTIMETRANSPORT',
		},
		{
			name: '자동카드결제',
			value: 'AUTO_CREDITCARD',
		},
	],
	PAY_STATUS: [
		{
			name: '결제완료',
			value: 'PAID',
		},
		{
			name: '결제대기',
			value: 'WAIT',
		},
		{
			name: '결제DROP',
			value: 'DROP',
		},
	],
	SYSTEM_APPLY_STATUS: [
		{
			name: '완료',
			value: 'SUCCESS',
		},
		{
			name: '실패',
			value: 'FAIL',
		},
		{
			name: '대기',
			value: 'WAIT',
		},
		{
			name: '해당없음',
			value: 'NONE',
		},
		{
			name: '취소',
			value: 'CANCEL',
		},
	],
});

export const estimateTermsData = [
	{
		approved: false,
		createdAt: '2021-04-27',
		creator: '박찬욱',
		id: 1,
		new: false,
		required: true,
		requiredAllSearch: false,
		serviceType: 'SAAS',
		termsDetails: [
			{
				announcementDate: null,
				bodyTemplate:
					'<div class="terms_box terms_privacy"><p>수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.</p><table><tbody><tr><th class="col1" scope="col">수집 및 이용목적</th><th class="col2" scope="col">항목</th><th class="col3" scope="col">보관기간</th></tr><tr><td>- 상담 접수 및 처리<br />- 처리 내역 보관 용도<br />- 중복 상담 확인</td><td>(필수) 회사명, 담당자명, 연락처, 이메일</td><td class="text_point"><strong style="color:#3851E7">수집 및 이용 동의일로부터 12개월<br />(단, 요청 시 삭제)</strong></td></tr></tbody></table></div><div class="terms_note"><p>※ 귀하는 위 개인정보 수집 및 이용을 거부할 수 있으나, 동의를 거부하실 경우 상담을 받으실 수 없습니다. 귀하의 상담 내용은 원활한 상담을 위하여 보관되며, 홈페이지에 게시된 개인정보처리방침에 따라 처리됩니다.</p></div>',
				createdAt: '2021-04-14T16:26:41.755129',
				enforcementDate: null,
				headerTemplate: null,
				id: 1,
				lastVersion: false,
				modifier: null,
				modifyReason: null,
				new: false,
				updatedAt: '2021-04-14T16:26:41.755129',
				useApi: false,
				version: null,
			},
		],
		termsName: '개인 정보 수집 및 이용에 동의합니다.',
		termsType: 'TERMS_OF_PRIVACY',
		updatedAt: '2021-04-14T16:26:41.743161',
		useForm: true,
		useFormAllSearch: false,
	},
	{
		approved: false,
		createdAt: '2021-04-27',
		creator: '박찬욱',
		id: 2,
		new: false,
		required: false,
		requiredAllSearch: false,
		serviceType: 'SAAS',
		termsDetails: [
			{
				announcementDate: null,
				bodyTemplate:
					'<div class="terms_box terms_privacy"><p>동의한 경우에 한해 최신 업데이트 소식, 프로모션 이벤트 등을 전달해드립니다.<br />수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.</p><table><tbody><tr><th class="col1" scope="col">수집 및 목적</th><th class="col2" scope="col">항목</th><th class="col3" scope="col">보관기간</th></tr><tr><td class="text_point">제품/솔루션/서비스 마케팅<br />광고 수신<br />( <a class="btn_mini" href="http://www.daou.co.kr/index.php/ko/biz/" target="_blank">제품내역</a> 클릭하여 확인)</td><td>연락처, 이메일</td><td class="text_point">수집 및 이용 동의일로부터 24개월<br />(단, 요청 시 삭제)</td></tr></tbody></table></div><div class="terms_note"><p>※ 동의한 경우에 한해 신제품, 최신 업데이트 소식 등을 전달해 드리며, 제품/솔루션/서비스 상세내역은 [제품내역]을 클릭하여 확인할 수 있습니다.</p><p>※ 귀하는 상기 마케팅 수집 활용을 거부할 수 있으며, 동의를 거부하실 경우 최신 정보 수신 및 자료의 열람이 제한될 수 있습니다.</p></div>',
				createdAt: '2021-04-14T16:26:41.755129',
				enforcementDate: null,
				headerTemplate: null,
				id: 2,
				lastVersion: false,
				modifier: null,
				modifyReason: null,
				new: false,
				updatedAt: '2021-04-14T16:26:41.755129',
				useApi: false,
				version: null,
			},
		],
		termsName: '마케팅 수집 및 활용에 동의합니다.',
		termsType: 'TERMS_OF_MARKETING',
		updatedAt: '2021-04-14T16:26:41.743161',
		useForm: true,
		useFormAllSearch: false,
	},
];
