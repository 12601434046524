import { product } from './index';
export const getEndDate = async (data) => {
	return await product.get(`/api/doms/public/membership/enddate`, {
		params: data,
	});
};
//기본서비스 조회
export const getBaseChannelList = async (data) => {
	return await product.get(`/api/doms/public/product/base/code/${data}`);
};
//부가서비스 조회
export const getAddonChannelList = async (data) => {
	return await product.get(`/api/doms/public/product/addon/available/base/${data}`);
};
//기본서비스 옵션(신규) 상품 조회
export const getBaseChannelOptionItemList = async (data) => {
	return await product.get(`/api/doms/public/product/item/orderType/NEW/base/${data}`);
};
//기본서비스 옵션(연장 or 추가) 상품 조회
export const getBaseChannelAddExtensionOptionItemList = async (data) => {
	return await product.get(`/api/doms/public/product/item/orderType/${data.orderType}/base/${data.code}`);
};
//부가서비스 옵션(신규) 상품 조회
export const getAddonChannelOptionItemList = async (data) => {
	return await product.get(`/api/doms/public/product/item/orderType/NEW/addon/${data}`);
};
//부가서비스 옵션(연장) 상품 조회
export const getAddonChannelExtensionOptionItemList = async (data) => {
	return await product.get(`/api/doms/public/product/item/orderType/EXTEND/addon/${data}`);
};
//기본서비스 옵션 상품 조회 (상품코드로)
export const getBaseChannelOptionByCode = async (code) => {
	return await product.get(`/api/doms/public/product/item/code/${code}`);
};
// 기본서비스 신요금제 전환 대상 여부 체크
export const checkBaseChannelIsConvertable = async (id) => {
	return await product.get(`/api/doms/public/product/base/${id}/is-convertable`);
};

//모든 상품그룹 조회
export const getItemGroups = () => {
	return product.get(`/api/doms/public/product/item/groups?size=100`);
};

//특정 상품그룹 조회
export const getItemGroup = (data) => {
	return product.get(`/api/doms/public/product/item/group/${data}`);
};
//상품연동 주소 조회
export const getIntegrationList = () => {
	return product.get(`/api/`);
};
//상품 리스트
export const getItemList = (data) => {
	return product.get(`/api/doms/public/product/items`);
};
//상품 조회
export const getItem = (data) => {
	return product.get(`api/doms/public/product/item/${data.id}`);
};
//부가 서비스 리스트
export const getAddonList = (data) => {
	return product.get(`/api/doms/public/product/addons`);
};
//프로모션 조회
export const getTodayPromotionList = async () => {
	return await product.get(`/api/doms/public/promotion`);
};
