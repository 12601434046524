import {
	getPartner,
	cancleOrder,
	getOrder,
	getOrderList,
	getRemainDays,
	getMembership,
	getMembershipById,
	getCustomer,
	updateCustomer,
	saveAutoPaymentCardInfo,
	cancelAutoPaymentCardInfo,
	searchAutoPaymentCardInfo,
	checkdomain,
	checkurl,
	getCompanyUsage,
	getAccountUsage,
	changePayType,
	setAutoExtension,
	userSso,
	checkMultiCompany,
	getAdditionalOptions,
	fetchSiteStatus,
	setSiteStatus,
	createSite,
	deleteSite,
	putAccountOnOff,
	updateSite,
	changeAddonAdmin,
} from '@/api/user';

import store from '@/store';
import _ from 'lodash';

export default {
	state: {
		sso: {
			role: 'SITE',
		},
		membership: {},
		partnerInfo: null,
		siteStatus: {
			sites: [],
		},
	},
	getters: {
		getPartnerInfo(state) {
			return state.partnerInfo;
		},
		getServiceType(state) {
			let membership = state.membership;
			let serviceType;
			if (!_.isUndefined(membership.baseChannelInfoModel)) {
				serviceType = membership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType;
			}
			return serviceType;
		},
		isSaaS(state, getters) {
			return getters.getServiceType === 'SAAS';
		},
		isInstall(state, getters) {
			return getters.getServiceType !== 'SAAS';
		},
		isSiteAdmin(state) {
			return state.sso.role === 'SITE';
		},
		isDomsAdmin(state) {
			return state.sso.role === 'ADMIN';
		},
		isSaaSReseller(state, getters) {
			return getters.isSaaS && state.membership.partnerSimpleModel.salesType === 'RESELLER';
		},
	},
	mutations: {
		setPartnerInfo(state, value) {
			state.partnerInfo = value;
		},
		GOT_MEMBERSHIP(state, data) {
			state.membership = data;
			store.dispatch('login/SET_ACCESS_MENUS', null, { root: true });
		},
		USER_SSO(state, data) {
			state.sso = data;
		},
		FETCH_SITE_STATUS(state, data) {
			data.sites.forEach((site) => {
				site.purchaseAccountQuota = site.purchaseAccountQuota || 0;
				site.purchaseCompanyQuota = site.purchaseCompanyQuota || 0;
				site.usedBankAccountCount = site.usedBankAccountCount || 0;
				site.usedPersonalCardCount = site.usedPersonalCardCount || 0;
			});
			state.siteStatus = Object.assign({}, data, {
				purchaseAccountQuota: data.purchaseAccountQuota || 0,
				purchaseCompanyQuota: data.purchaseCompanyQuota || 0,
				sites: data.sites,
			});
		},
		SET_SITE_STATUS(state, siteStatus) {
			state.siteStatus = siteStatus;
		},
		CREATE_SITE(state, site) {
			const { sites } = state.siteStatus;
			state.siteStatus.sites = [...sites, site];
		},
		UPDATE_SITE(state, site) {
			const { sites } = state.siteStatus;
			const siteIndex = sites.findIndex((item) => item.uuid === site.uuid);
			state.siteStatus.sites = [...sites.slice(0, siteIndex), site, ...sites.slice(siteIndex + 1)];
		},
		DELETE_SITE(state, uuid) {
			const { sites } = state.siteStatus;
			const deletedSiteIndex = sites.findIndex((site) => site.uuid === uuid);
			sites.splice(deletedSiteIndex, 1);
		},
		PUT_ACCOUNT_ONOFF(state, payload) {
			const { sites } = state.siteStatus;
			const siteIndex = sites.findIndex((item) => item.uuid === payload.uuid);
			const site = sites[siteIndex];
			const channelInfoIndex = site.channelInfo.findIndex((item) => item.code === 'ACCOUNT');
			const accountChannelInfo = site.channelInfo[channelInfoIndex] || {};
			const newAccountChannelInfo = { ...accountChannelInfo, use: payload.params.use, useMiniGnb: payload.params.use };
			const newChannelInfo = (site.channelInfo = [
				...site.channelInfo.slice(0, channelInfoIndex),
				newAccountChannelInfo,
				...site.channelInfo.slice(channelInfoIndex + 1),
			]);
			const updatedSite = { ...site, channelInfo: newChannelInfo };
			state.siteStatus.sites = [...sites.slice(0, siteIndex), updatedSite, ...sites.slice(siteIndex + 1)];
		},
	},
	actions: {
		async GET_ADDITIONAL_OPTIONS({ commit }, params) {
			const { data } = await getAdditionalOptions(params);
			return data;
		},
		async GETPARTNER({ commit }, params) {
			const { data } = await getPartner(params);
			return data;
		},
		async SETAUTOEXTENSION({ commit }, params) {
			const { data } = await setAutoExtension(params);
			return data;
		},
		async CHECKDOMAIN({ commit }, params) {
			const { data } = await checkdomain(params);
			return data;
		},
		async CHECKURL({ commit }, params) {
			const { data } = await checkurl(params);
			return data;
		},
		async CHECKMULTI_COMPANY({ commit }, params) {
			const { data } = await checkMultiCompany(params);
			return data;
		},
		async CANCLEORDER({ commit }, params) {
			const { data } = await cancleOrder(params);
			return data;
		},
		async GETORDER({ commit }, params) {
			const { data } = await getOrder(params);
			return data;
		},
		async GETORDERLIST({ commit }, params) {
			const { data } = await getOrderList(params);
			return data;
		},
		async GETMEMBERSHIP({ commit }, params) {
			// 멤버십 조회
			const { data } = await getMembership();
			commit('GOT_MEMBERSHIP', data);
			return data;
		},
		async GETMEMBERSHIPBYID({ commit }, params) {
			// 멤버십 조회
			const { data } = await getMembershipById(params);
			return data;
		},
		async GETREMAINDAYS({ commit }, params) {
			// 멤버십 조회
			const { data } = await getRemainDays(params);
			return data;
		},

		async GETCUSTOMER({ commit }, params) {
			// 고객정보 수정
			const { data } = await getCustomer(params);
			return data;
		},
		async UPDATECUSTOMER({ commit }, params) {
			// 고객정보 수정
			const { data } = await updateCustomer(params);
			return data;
		},
		// 자동결제 카드 정보
		async SAVEAUTOPAYMENTCARDINFO({ commit }, params) {
			// 등록
			const { data } = await saveAutoPaymentCardInfo(params);
			return data;
		},
		async CANCELAUTOPAYMENTCARDINFO({ commit }, params) {
			// 삭제
			await cancelAutoPaymentCardInfo(params);
		},
		async SEARCHAUTOPAYMENTCARDINFO({ commit }, params) {
			const { data } = await searchAutoPaymentCardInfo(params);
			return data;
		},
		async GETCOMPANYUSAGE({ commit }, params) {
			const { data } = await getCompanyUsage(params);
			return data;
		},
		async GET_ACCOUNT_USAGE({ commit }, params) {
			const { data } = await getAccountUsage(params);
			return data;
		},
		async CHANGEPAYTYPE({ commit }, params) {
			// 저장
			const { data } = await changePayType(params);
			return data;
		},
		async USER_SSO({ commit }, token) {
			const resp = await userSso(token);
			commit('USER_SSO', resp.data);
			return resp;
		},
		async FETCH_SITE_STATUS({ commit }) {
			let membershipId = this.state.user.sso.membershipId;
			if (!membershipId) {
				let sso = await userSso();
				commit('USER_SSO', sso.data);
				membershipId = sso.data.membershipId;
			}
			const resp = await fetchSiteStatus(membershipId);
			commit('FETCH_SITE_STATUS', resp.data.data);
			return resp;
		},
		async SET_SITE_STATUS({ commit }, data) {
			const resp = await setSiteStatus(data.siteStatus.sites, data.membershipId);
			if (resp.data.success) commit('SET_SITE_STATUS', data.siteStatus);
			return resp;
		},
		async CREATE_SITE({ commit }, data) {
			data.siteStatus.sites = data.siteStatus.sites.slice(0, -1);
			const resp = await setSiteStatus(data.siteStatus.sites, data.membershipId, data.uuid);
			if (resp.data.success) {
				commit('SET_SITE_STATUS', data.siteStatus);
				const createSiteResp = await createSite(data);
				if (createSiteResp.data.success) {
					let membershipId = this.state.user.sso.membershipId;
					const siteStatusResp = await fetchSiteStatus(membershipId);
					commit('FETCH_SITE_STATUS', siteStatusResp.data.data);
				}
				return createSiteResp;
			}
			return resp;
		},
		async UPDATE_SITE({ commit }, data) {
			const resp = await updateSite(data);
			if (resp.data.success) {
				commit('UPDATE_SITE', data.site);
			}
			return resp;
		},
		async DELETE_SITE({ commit }, data) {
			const resp = await deleteSite(data);
			if (resp.data.success) {
				commit('DELETE_SITE', data.uuid);
			}
			return resp;
		},
		async PUT_ACCOUNT_ONOFF({ commit }, payload) {
			const { data } = await putAccountOnOff(payload);
			if (data.status === 200) {
				commit('PUT_ACCOUNT_ONOFF', payload);
			}
			return data;
		},
		async CHANGE_ADDON_ADMIN({ state }, payload) {
			const { data } = await changeAddonAdmin(payload);
			return data;
		},
	},
};
