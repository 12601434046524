import Vue from 'vue';
import store from '@/store/index';

export const reverseItems = (items) => {
	return items.slice().reverse();
};

export const splitItems = (items) => {
	return items.split(',');
};

export const joinItems = (items, type) => {
	return items.join(type);
};

export const isEmpty = (value) => {
	if (value === null) return true;
	if (typeof value === 'undefined') return true;
	if (typeof value === 'string' && value === '') return true;
	if (typeof value === 'string' && value.trim() === '') return true;
	if (Array.isArray(value) && value.length < 1) return true;
	if (
		typeof value === 'object' &&
		value.constructor.name === 'Object' &&
		Object.keys(value).length < 1 &&
		Object.getOwnPropertyNames(value) < 1
	)
		return true;
	return !!(typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1);
};

export const isMobile = () => {
	let mobileKeyWords = [
		'iPhone',
		'iPod',
		'BlackBerry',
		'Android',
		'Windows CE',
		'LG',
		'MOT',
		'SAMSUNG',
		'SonyEricsson',
	];
	for (let word in mobileKeyWords) {
		if (navigator.userAgent.match(mobileKeyWords[word]) != null) {
			return true;
		}
	}
	return false;
};

export const byte = (bytes = 0, fixed = 1, withoutUnit = false) => {
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (bytes === 0) return 'n/a';
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
	if (i === 0) return `${bytes} ${sizes[i]}`;
	return `${(bytes / 1024 ** i).toFixed(fixed)}${withoutUnit ? '' : ' ' + sizes[i]}`;
};

export const byteToGigaByte = (quota) => {
	return parseInt(quota / 1024 / 1024 / 1024);
};

export const gigaByteToByte = (quota) => {
	return parseInt(quota * 1024 * 1024 * 1024);
};

export const checkMobile = () => {
	let varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기
	if (varUA.indexOf('android') > -1) {
		return 'ANDROID';
	} else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
		return 'IPHONE';
	}
};

export const findNameByValue = (dataList, value) => {
	if (!value) return '';
	const matchObj = dataList.find((arr) => {
		return arr.value === value;
	});
	return matchObj.name;
};

/* LocalDateTime 형태로 반환 (현재시간) */
export const currentLocalDateTime = () => {
	return Vue.prototype.$moment().format('YYYY-MM-DDTHH:mm:ss');
};

/* LocalDateTime 형태로 반환 (검색) */
export const fromLocalDateTime = (date) => {
	return Vue.prototype.$moment(date).format('YYYY-MM-DDT00:00:00');
};

/* LocalDateTime 형태로 반환 (검색) */
export const toLocalDateTime = (date) => {
	return Vue.prototype.$moment(date).format('YYYY-MM-DDT23:59:59');
};

export const isDomsAdmin = () => {
	const loginId = sessionStorage.getItem('loginId');
	return !!(loginId && loginId.includes('DOMS_'));
};

export const isOrderMultiCompany = () => {
	const options = store.getters.getBasicItemSelected[0].optionItemSelected;
	const multiCompany = options.filter(
		(option) => (option.itemType === 'UNABLE_CHANGE_QUANTITY') & (option.name === '멀티컴퍼니'),
	);
	return multiCompany.length > 0;
};

export const hasElement = (parent, targetElement) => {
	if (!parent || !targetElement) return false;
	let result = false;

	const _hasElement = (currentElement) => {
		for (const element of currentElement.childNodes) {
			if (element === targetElement) {
				result = true;
				break;
			} else {
				_hasElement(element);
			}
		}
	};
	_hasElement(parent);

	return result;
};
