import axios from 'axios';
import { setInterceptors, setInterceptorsWithRefreshToken, withCredentials } from './config/interceptors';

function create(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {
				'Content-Type': 'application/json',
			},
		}),
	);
	withCredentials(instance);
	return instance;
}

function createWithAuth(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: { 'Content-Type': 'application/json' },
		}),
	);
	setInterceptors(instance);
	return instance;
}

function createMultipart(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
			},
		}),
	);
	return instance;
}
function createWithAuthAboutRefresh(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: { 'Content-Type': 'application/json' },
		}),
	);
	setInterceptorsWithRefreshToken(instance);
	return instance;
}

function createWithFile(url) {
	const instance = axios.create(
		Object.assign({
			baseURL: url,
			headers: {},
		}),
	);
	return instance;
}
export const reApi = create(`${process.env.VUE_APP_API_URL}`);
export const terms = create(`${process.env.VUE_APP_API_URL}`);
export const multipart = createMultipart(`${process.env.VUE_APP_API_URL}`);
export const product = create(`${process.env.VUE_APP_API_URL}`);
export const apply = create(`${process.env.VUE_APP_PAY_SERVER}`);
export const payment = create(`${process.env.VUE_APP_PAY_SERVER}`);
export const order = create(`${process.env.VUE_APP_API_URL}`);
export const user = create(`${process.env.VUE_APP_API_URL}`);
export const manager = create(`${process.env.VUE_APP_API_URL}`);
export const loginUser = create(`${process.env.VUE_APP_API_URL}`);
export const logoutUser = createWithAuthAboutRefresh(`${process.env.VUE_APP_API_URL}`);
export const attach = createWithFile(`${process.env.VUE_APP_FILE_API_URL}`);
export const common = create(`${process.env.VUE_APP_API_URL}`);
