export default {
	update(el, binding, vnode) {
		// 커스텀(숫자만) : v-doms-inputValidate:regexp="'[^0-9]'"
		if (binding.arg == 'regexp') {
			const expression = binding.expression.replace(/'/g, '');
			el.value = el.value.replace(new RegExp(expression, 'g'), '');
			return;
		}

		// 숫자만 : v-doms-inputValidate:onlyNumber=""
		if (binding.arg == 'onlyNumber') {
			el.value = el.value.replace(/[^0-9]/g, '');
			return;
		}

		//앞뒤공백제거 : v-doms-inputValidate:leadingTrailingBlank=""
		if (binding.arg == 'leadingTrailingBlank') {
			el.value = el.value.replace(/(^\s*)|(\s*$)/g, '');
			return;
		}
	},
};
