import { reApi } from '@/api/index';
import router from '@/router';
import store from '@/store/index';
import { getAuthFromCookie, getUserFromCookie } from '@/utils/cookies';
import { refreshToken } from '@/api/login';
import { alert } from '@/plugins/custom/modules/dialog';

function setInterceptors(instance) {
	instance.interceptors.request.use(
		(config) => {
			config.headers.Authorization = getAccessToken();
			console.log('interceptors');
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	instance.interceptors.response.use(
		(response) => {
			if (response.data.error) {
				// todo-star 401 추후 재정의
				if (response.data.status == '400' || response.data.status == '401') {
					alert(response.data.errorMessage, response.data.errorDescription);
				} else if (response.data.status == '500') {
					alert(
						'서버 에러 발생',
						'정의되지 않은 서버 에러로, 서버관리자의 확인이 필요한 에러입니다. 관리자에게 문의해주세요.',
					);
					console.log(response.data);
				} else if (response.data.status == '409' || response.data.status == '406') {
					store.commit('SET_INIT_USER');
					const reason = response.data.status == '409' ? 'concurrent' : 'expireToken';
					router.push({ name: 'Login', params: { reason: reason } });
				} else {
					console.log(response.data);
				}
			}
			return response;
		},
		async (error) => {
			const errorCode = error.response.status;
			if (errorCode === 406) {
				/*
				0. 중요! 해당 에러는 필터에서 내보내줌
				1. 리프레쉬 토큰을 담아서 서버에 호출
				2. 반환값으로 access_token 을 받고 store 에 저장
				3. 갱신된 access_token 으로 서버에 재요청
				 */
				try {
					const apiConfig = {
						headers: {
							Accept: 'application/json, text/plain, */*',
							Authorization: getRefreshToken(),
						},
					};

					const response = await refreshToken(getUserFromCookie(), apiConfig);
					store.commit('SET_ACCESS_TOKEN', response.data.data.token);

					const errorConfig = error.response.config;
					const config = { ...errorConfig };

					config.headers = {
						Authorization: getAccessToken(),
					};

					// axios 자체가 header 세팅이 안되는 버그가 있는것같다.
					// headers 의 'Content-Type': 'application/json' 이 아닌 경우가 생기면
					// 수정이 필요하다.
					const result = await reApi(config);
					return result;
				} catch (e) {
					store.commit('SET_INIT_USER');
					router.push({ name: 'Login', params: { reason: 'expireToken' } });
				}
			}
			await Promise.reject(error.response);
		},
	);
	return instance;
}

function setInterceptorsWithRefreshToken(instance) {
	instance.interceptors.request.use(
		(config) => {
			config.headers.Authorization = getRefreshToken();
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	instance.interceptors.response.use(
		(response) => {
			return response;
		},
		async (error) => {
			await Promise.reject(error.response);
		},
	);
	return instance;
}

function getRefreshToken() {
	const prefix = 'Bearer ';
	return prefix + getAuthFromCookie().refresh_token;
}

function getAccessToken() {
	const prefix = 'Bearer ';
	return prefix + getAuthFromCookie().access_token;
}

function withCredentials(instance) {
	instance.interceptors.request.use(
		(config) => {
			config.withCredentials = true;
			return config;
		},
		(error) => Promise.reject(error.response),
	);
	instance.interceptors.response.use(
		(response) => {
			if (response.data.error) {
				if (response.data.status == '400' || response.data.status == '401') {
					window.alert(response.data.errorMessage, response.data.errorDescription);
				} else if (response.data.status == '500') {
					const defaultErrorMsg =
						'정의되지 않은 서버 에러로, 서버관리자의 확인이 필요한 에러입니다. 관리자에게 문의해주세요.';
					window.alert(
						'서버 에러 발생',
						response.data.errorDescription ? response.data.errorDescription : defaultErrorMsg,
					);
				} else {
					const errorMsg = response.data.status + ' 오류가 발생했습니다. 관리자에게 문의해주세요.';
					window.alert('에러 발생', errorMsg);
				}
			}
			return response;
		},
		async (error) => {
			const response = error.response;
			if (response.status == '400' || response.status == '401') {
				window.alert('잘못된 요청입니다. 관리자에게 문의해주세요.');
			}
			await Promise.reject(error.response);
		},
	);
	return instance;
}
export { setInterceptors, setInterceptorsWithRefreshToken, withCredentials };
