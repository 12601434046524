import Vue from 'vue';
import Vuex from 'vuex';
import login from './modules/login';
import common from './modules/common';
import apply from './modules/apply';
import order from './modules/order';
import addExtension from './modules/addExtension';
import user from './modules/user';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		common,
		apply,
		login,
		order,
		addExtension,
		user,
	},
});
