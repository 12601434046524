import * as dialog from './modules/dialog';
import * as modal from './modules/modal';
import * as validate from './modules/validator';
import * as toast from './modules/toast';

export default {
	dialog,
	modal,
	validate,
	toast,
};
