import { loginUser, logoutUser } from './index';

export const login = (loginData) => {
	return loginUser.post('/api/public/login', JSON.stringify(loginData));
};

export const logout = (data, config) => {
	logoutUser.post('/api/public/logout', data, config);
};

export const initPassword = async (data) => {
	return await loginUser.post('/api/public/initPassword', JSON.stringify(data));
};

export const refreshToken = async (data, config) => {
	return await loginUser.post('/api/public/refreshToken', JSON.stringify(data), config);
};
