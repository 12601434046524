import Vue from 'vue';
export const findNameByValue = (value, dataList) => {
	if (!value) return '';
	const matchObj = dataList.find((arr) => {
		return arr.value === value;
	});
	return matchObj.name;
};

export const currency = (value) => {
	if (!value) return 0;
	return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const convertYYMMDD = (value) => {
	if (!value) return '';
	return Vue.prototype.$moment(value).format('YYYY-MM-DD');
};

export const convertRequiredField = (value) => {
	return value ? '필수' : '선택';
};

export const convertUseField = (value) => {
	return value ? '사용' : '사용안함';
};
