import VueCookies from 'vue-cookies';

VueCookies.config('14d');

export const saveUserIdToCookie = (userId) => {
	VueCookies.set('savedUserId', userId);
};

export const getUserIdToCookie = () => {
	return VueCookies.get('savedUserId');
};

export const saveAuthToCookie = (accessToken, refreshToken) => {
	saveAccessTokenToCookie(accessToken);
	saveRefreshTokenToCookie(refreshToken);
};

export const saveAccessTokenToCookie = (accessToken) => {
	VueCookies.set('doms_access_token', accessToken);
};

export const saveRefreshTokenToCookie = (refreshToken) => {
	VueCookies.set('doms_refresh_token', refreshToken);
};

export const saveUserToCookie = (value) => {
	VueCookies.set('doms_user', value);
};

export const getUserFromCookie = () => {
	return VueCookies.get('doms_user');
};

export const getAuthFromCookie = () => {
	return {
		access_token: VueCookies.get('doms_access_token'),
		refresh_token: VueCookies.get('doms_refresh_token'),
	};
};

export const deleteCookie = (value) => {
	VueCookies.remove(value);
};

export const noticeCloseForever = () => {
	VueCookies.set('isCloseForever', true);
};

export const isNoticeCloseForever = () => {
	return VueCookies.get('isCloseForever');
};
