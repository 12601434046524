import store from '@/store';
export default {
	bind(el, binding, vnode) {
		store.dispatch('SET_VALIDATATE_FUNC', {
			key: binding.arg,
			func: binding.expression,
		});
		store.dispatch('SET_VALIDATATE_VAL', {
			key: binding.arg,
			value: el.value,
		});
	},
	update(el, binding, vnode) {
		store.dispatch('SET_VALIDATATE_VAL', {
			key: binding.arg,
			value: el.value,
		});
	},
	unbind(e, binding) {
		store.dispatch('INIT_VALIDATATE');
	},
};
