import { confirmBusinessNumber, duplicateBusinessNum, checkBusinessLicenseNumber } from '@/api/common';
export default {
	state: {
		pageInfo: {
			type: '',
			step: '',
		},
	},
	actions: {
		async CONFIRM_BUSINESSNUM({ commit }, params) {
			const { data } = await confirmBusinessNumber(params);
			return data;
		},
		async DUPLICATE_BUSINESSNUM({ commit }, params) {
			const { data } = await duplicateBusinessNum(params);
			return data;
		},
		async CHECK_BUSINESSLICENSENUMBER({ commit }, params) {
			const { data } = await checkBusinessLicenseNumber(params);
			return data;
		},
	},
	mutations: {
		setCurrentStep(state, payload) {
			state.pageInfo.type = payload.type;
			state.pageInfo.step = payload.step;
		},
	},
	getters: {
		getStepClassAttr: (state) => (targetStep) => {
			if (state.pageInfo.step === targetStep) {
				return 'on';
			} else if (state.pageInfo.step > targetStep) {
				return 'complete';
			}
		},

		isFirstPage: (state) => {
			return state.pageInfo.step === 'step1';
		},
	},
};
