import { common, attach } from './index';

export const fileUpload = async (formData) => {
	return await attach.post(`/api/attachment`, formData);
};
export const fileApply = async (data) => {
	return await attach.put(`/api/attachment`, data);
};

export const getFile = async (id) => {
	return await attach.get(`/api/attachment/${id}`);
};

export const getPortalUserList = async () => {
	return await common.get(`/api/portal/workers`);
};

export const portalLogin = async (formData) => {
	return await common.post(`/api/portal/login`, JSON.stringify(formData));
};

export const syncPortalWorker = async (formData) => {
	return await common.get(`/api/portal/sync`);
};
export const addComment = async (data) => {
	await common.post('/api/history/comment', data);
};

export const deleteComment = async (id) => {
	await common.delete(`/api/history/${id}`);
};

export const deleteAttach = async (id, attachId) => {
	await common.delete(`/api/history/${id}/${attachId}`);
};

export const searchHistory = async (data) => {
	return await common.get('/api/history/search', { params: data });
};

export const confirmBusinessNumber = async (data) => {
	return await common.post('/api/doms/public/openapi/business/info', data);
};

export const duplicateBusinessNum = async (data) => {
	return await common.get('/api/doms/public/openapi/business/license/duplicate', {
		params: data,
	});
};

export const checkBusinessLicenseNumber = async (data) => {
	return await common.get('/api/doms/public/customer/license/validation', {
		params: data,
	});
};
