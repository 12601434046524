import { user, payment } from './index';
import { byteToGigaByte, gigaByteToByte } from '@/utils/utils';
// import {
// 	createMockSite,
// 	deleteMockSite,
// 	fetchMockSession,
// 	fetchMockUsing,
// 	getMockSiteStatus,
// 	setMockSites,
// 	toggleAccount,
// 	updateMockSite,
// } from '@/api/mock';

// 파트너사 조회
export const getPartner = async (code) => {
	return await user.get(`/api/doms/partner/${code}`);
};

// 멤버십조회(가입현황)
export const getMembership = async () => {
	return await user.get(`/api/paysystem/using`, {
		timeout: 1000,
	});
	// return fetchMockUsing();
};

export const getMembershipById = async (id) => {
	return await user.get(`/api/doms/membership/${id}`);
};

// 고객 정보 조회
export const getCustomer = async (id) => {
	return await user.get(`/api/doms/customer/${id}`);
};
// 고객 정보 수정 (manager 수정 시 사용)
export const updateCustomer = async (data) => {
	return await user.put(`/api/doms/customer`, data);
};

// 잔여일 조회 (추가 시 필요)
export const getRemainDays = async (data) => {
	return await user.get(`/api/doms/public/membership/${data.membershipId}/remaindate?startDate=${data.startDate}`);
};

export const getOrderList = async (data) => {
	return await user.get(`/api/paysystem/membership/orders`, {
		params: data,
	});
};

export const getOrder = async (orderId) => {
	return await user.get(`/api/paysystem/order/${orderId}`);
};

export const cancleOrder = async (orderId) => {
	return await user.put(`/api/paysystem/order/drop/${orderId}`);
};

// 도메인 중복 체크
export const checkdomain = async (domain) => {
	return await user.get(`/api/doms/public/checkdomain?domain=${domain}`);
};

// url 중복 체크
export const checkurl = async (url) => {
	return await user.get(`/api/doms/public/checkurl?url=${url}`);
};

//멀티컴퍼니 확인
export const checkMultiCompany = (data) => {
	return user.get('/api/doms/public/checkmulticompany', { params: data });
};

// 멤버십 사용량 조회
export const getCompanyUsage = async () => {
	return await user.get(`/api/paysystem/membership/usage`);
};

// 경리회계 옵션상품 사용량 조회
export const getAccountUsage = async () => {
	return await user.get(`/api/paysystem/addon/usage`);
};

export const changePayType = async (data) => {
	return await user.put(`/api/doms/order/paytype`, data);
};
// 자동결제 카드 등록
export const saveAutoPaymentCardInfo = async (data) => {
	return await payment.post(`/api/autoPayment/card/save`, data);
};
// 자동결제 취소
export const cancelAutoPaymentCardInfo = (membershipId) => {
	payment.delete(`/api/autoPayment/cancel/${membershipId}`);
};

// 자동결제 카드 조회
export const searchAutoPaymentCardInfo = async (customerId) => {
	return await payment.get(`/api/autoPayment/card/${customerId}`);
};
export const setAutoExtension = async (data) => {
	// 신용카드 자동연장 연결
	return await payment.post(`/api/kiwoom/batch`, data);
};

const requestMap = {};
export const userSso = async (token = sessionStorage.getItem('userToken')) => {
	if (requestMap['sso']) return requestMap['sso'];
	let promise;
	if (token) {
		const ssoRequest = user.get(`/api/user/sso?token=${token}`);
		requestMap['sso'] = ssoRequest;
		promise = await ssoRequest;
		delete requestMap['sso'];
	} else {
		promise = await user.get(`/api/user/session`);
		// promise = fetchMockSession();
	}
	return promise;
};

export const getAdditionalOptions = async (data) => {
	return await user.get(`/api/doms/enum/constants/${data.type}`);
};

export const fetchSiteStatus = async (membershipId) => {
	// const resp = await getMockSiteStatus(membershipId);
	const resp = await user.get(`/api/doms/membership/resource/${membershipId}`);
	const siteStatus = resp.data.data;
	siteStatus.usedQuota = byteToGigaByte(siteStatus.usedQuota);
	siteStatus.purchaseQuota = byteToGigaByte(siteStatus.purchaseQuota);
	siteStatus.purchaseAccountQuota = byteToGigaByte(siteStatus.purchaseAccountQuota);
	siteStatus.purchaseCompanyQuota = byteToGigaByte(siteStatus.purchaseCompanyQuota);
	siteStatus.sites.forEach((site) => {
		site.usedAccountQuota = byteToGigaByte(site.usedAccountQuota);
		site.usedCompanyQuota = byteToGigaByte(site.usedCompanyQuota);
		site.purchaseAccountQuota = byteToGigaByte(site.purchaseAccountQuota);
		site.purchaseCompanyQuota = byteToGigaByte(site.purchaseCompanyQuota);
	});
	return resp;
};

export const setSiteStatus = async (sites, membershipId) => {
	sites.forEach((site) => {
		site.membershipId = membershipId;
		site.domainName = site.domain;
		site.companyName = site.siteName;
		site.siteUrl = site.url;
		site.managerName = site.adminName;
		site.userCount = site.purchaseUserCount;
		site.companyCreditCardCount = site.purchaseCompanyCreditCardCount;
		site.personalCardCount = site.purchasePersonalCardCount;
		site.taxBillCount = site.purchaseTaxBillCount;
		site.bankAccountCount = site.purchaseBankAccountCount;
		site.meetingRoomCount = site.purchaseMeetingRoomCount;
		site.companyQuota = gigaByteToByte(site.purchaseCompanyQuota);
		site.totalAccountQuota = gigaByteToByte(site.purchaseAccountQuota);
	});
	// return await setMockSites(sites);
	return await user.put(`/api/company/resource/update/${membershipId}`, sites);
};

export const createSite = async (data) => {
	const requestModel = {
		membershipId: data.membershipId,
		companyId: null,
		uuid: null,
		// domainName: new Date().getTime() + '',
		domainName: data.site.domain,
		companyName: data.site.siteName,
		siteUrl: data.site.url,
		managerId: data.site.adminId,
		managerName: data.site.adminName,
		managerPassword: data.site.password,
		managerPasswordConfirm: data.site.passwordConfirm,
		userCount: data.site.purchaseUserCount,
		companyQuota: gigaByteToByte(data.site.purchaseCompanyQuota),
		totalAccountQuota: gigaByteToByte(data.site.purchaseAccountQuota),
		meetingRoomCount: data.site.purchaseMeetingRoomCount,
		companyCreditCardCount: data.site.purchaseCompanyCreditCardCount,
		personalCardCount: data.site.purchasePersonalCardCount,
		taxBillCount: data.site.purchaseTaxBillCount,
		bankAccountCount: data.site.purchaseBankAccountCount,
	};
	// return await createMockSite(data.site);
	return await user.post(`/api/company/create`, requestModel);
};

export const updateSite = async (data) => {
	const requestModel = {
		membershipId: data.membershipId,
		companyId: data.site.companyId,
		uuid: data.uuid,
		domainName: data.site.domain,
		companyName: data.site.siteName,
		siteUrl: data.site.url,
		managerName: data.site.adminName,
		userCount: data.site.purchaseUserCount,
		companyQuota: gigaByteToByte(data.site.purchaseCompanyQuota),
		totalAccountQuota: gigaByteToByte(data.site.purchaseAccountQuota),
		meetingRoomCount: data.site.purchaseMeetingRoomCount,
		companyCreditCardCount: data.site.purchaseCompanyCreditCardCount,
		personalCardCount: data.site.purchasePersonalCardCount,
		taxBillCount: data.site.purchaseTaxBillCount,
		bankAccountCount: data.site.purchaseBankAccountCount,
	};
	// return await updateMockSite(data.site);
	return await user.put(`/api/company/update/${data.membershipId}/${data.uuid}`, requestModel);
};

export const deleteSite = async (data) => {
	// return await deleteMockSite(data.uuid);
	return await user.delete(`/api/company/delete/${data.membershipId}/${data.uuid}`);
};

// 경리회계 ON/OFF
export const putAccountOnOff = async (data) => {
	// return await toggleAccount(data);
	return await user.put(`/api/addon/switch/${data.id}/${data.uuid}`, data.params);
};

// 경리회계 관리자 ID 변경
export const changeAddonAdmin = async (data) => {
	return await user.put(`/api/addon/account/admin`, data);
};
