<template>
	<div class="com_ipt_calendar" style="display: inline-block; width: 160px">
		<v-date-picker class="inline-block h-full" v-model="selectDate" :min-date="minDate">
			<template v-slot="{ inputValue, togglePopover }">
				<div class="com_ipt calendar" style="width: 100% !important">
					<i class="ic_set ic_cal" @click="popover(togglePopover)"></i>
					<input class="ipt_normal" type="text" :value="inputValue" @click="popover(togglePopover)" readonly />
				</div>
			</template>
		</v-date-picker>
	</div>
</template>

<script>
export default {
	name: 'DatePicker',
	props: {
		minDate: {
			type: Date,
		},
		value: {},
		readonly: {
			type: Boolean,
			default() {
				return false;
			},
		},
	},
	data() {
		return {
			date: this.value,
		};
	},
	computed: {
		selectDate: {
			get() {
				return this.value;
			},
			set(value) {
				this.date = value;
			},
		},
	},
	watch: {
		date() {
			this.$emit('input', this.customFormatter(this.date));
			this.dateSelected();
		},
	},
	methods: {
		customFormatter(date) {
			return date != null ? this.$moment(date).format('YYYY-MM-DD') : '';
		},
		dateSelected() {
			this.$nextTick(() => {
				this.$emit('changeDate');
			});
		},
		popover(fn) {
			if (!this.readonly) {
				fn();
			}
		},
	},
};
</script>

<style></style>
