/**
 * api/paysystem/using -> partnerSimpleModel.salesType // RESELLER, !RESELLER
 * api/paysystem/using -> baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType // SAAS, !SAAS
 * api/user/sso -> role // ADMIN, SYSTEM, SITE
 * */

/**					공유형			공유형		단독/설치형		단독/설치형		단독/설치형		공통
 * 					사이트관리자		리셀러		시스템관리자		사이트관리자		리셀러			DOMS관리자
 *
 * 회사정보			O				O			O				O				O				O
 * 가입현황			O				O			O				O				O				O
 * 사이트현황		O				O			O				O				O				O
 * 주문/계약내역		O				X			O				O				X				O
 *
 * 스토어 추가/연장	O				X			X				X				X				O
 */

/**
const menus = [
	{
		key: 'Membership',
		name: '멤버십',
		child: [
			{
				key: 'companyInfo',
				menuGroup: 'Membership',
				name: '회사정보',
				accessMenuType: 'MYSTORE',
				url: '/user/myStore/CompanyInfo',
			},
			{
				key: 'membershipStatus',
				menuGroup: 'Membership',
				name: '가입현황', // 전체
				accessMenuType: 'MYSTORE',
				url: '/user/myStore/MembershipStatus',
			},
			{
				key: 'siteManagement',
				menuGroup: 'Membership',
				name: '사이트현황',
				accessMenuType: 'MYSTORE',
				url: '/user/myStore/SiteStatus',
			},
			{
				key: 'orderHistory',
				menuGroup: 'Membership',
				name: '주문/구매내역',
				accessMenuType: 'MYSTORE',
				url: '/user/myStore/OrderHistory',
			},
		],
	},
	{
		key: 'Store',
		name: '스토어',
		child: [
			{
				key: 'addExtension',
				menuGroup: 'Store',
				name: '서비스 추가/연장',
				accessMenuType: 'ORDER',
				url: '/order/addExtension',
			},
		],
	},
];
 */

const membershipMenuChild = [
	{
		key: 'companyInfo',
		menuGroup: 'Membership',
		name: '회사정보',
		accessMenuType: 'MYSTORE',
		url: '/user/myStore/CompanyInfo',
	},
	{
		key: 'membershipStatus',
		menuGroup: 'Membership',
		name: '가입현황',
		accessMenuType: 'MYSTORE',
		url: '/user/myStore/MembershipStatus',
	},
	{
		key: 'siteManagement',
		menuGroup: 'Membership',
		name: '사이트현황',
		accessMenuType: 'MYSTORE',
		url: '/user/myStore/SiteStatus',
	},
];

const membershipMenu = {
	key: 'membership',
	name: '멤버십',
	child: membershipMenuChild,
};

const storeMenu = {
	key: 'store',
	name: '스토어',
	child: [
		{
			key: 'addExtension',
			menuGroup: 'Store',
			name: '서비스 추가/연장',
			accessMenuType: 'ORDER',
			url: '/order/addExtension',
		},
	],
};

const orderHistoryMenu = {
	key: 'orderHistory',
	menuGroup: 'Membership',
	name: '주문/구매내역',
	accessMenuType: 'MYSTORE',
	url: '/user/myStore/OrderHistory',
};

export const initialMenus = [membershipMenu];

export const sideMenu = [
	{
		...membershipMenu,
		child: [].concat(membershipMenuChild, orderHistoryMenu),
	},
	storeMenu,
];
