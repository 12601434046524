import _ from 'lodash';

import {
	saveAuthToCookie,
	saveUserToCookie,
	deleteCookie,
	getUserFromCookie,
	saveUserIdToCookie,
	saveAccessTokenToCookie,
	getAuthFromCookie,
} from '@/utils/cookies.js';

import { login, logout, initPassword } from '@/api/login';

import { initialMenus, sideMenu } from '@/components/common/sideMenuData';

import store from '@/store';

// state
export default {
	namespaced: true,

	state: {
		accessMenus: initialMenus,
		roles: [],
	},

	getters: {
		isLoggedIn(state) {
			return getUserFromCookie();
		},
		userToken(state) {
			return getAuthFromCookie().access_token;
		},
		refreshToken(state) {
			return getAuthFromCookie().refresh_token;
		},
		getAccessMenus(state) {
			if (!state.accessMenus.length) {
				return JSON.parse(decodeURIComponent(localStorage.getItem('accessMenus')));
			}
			return state.accessMenus;
		},
		getRoles(state) {
			if (!state.roles.length) {
				return JSON.parse(decodeURIComponent(localStorage.getItem('roles')));
			}
			return state.roles;
		},
	},

	actions: {
		async RECEIVE_AUTHCODE({ commit }, param) {
			const { data } = await login(param);
			const responseData = data.data;
			if (responseData == undefined) {
				throw data;
			}
			return responseData;
		},
		async LOGIN({ commit }, param) {
			const { data } = await login(param);
			const responseData = data.data;
			if (responseData == undefined) {
				throw data;
			}
			if (!responseData.forceChangePassword && !responseData.requireBounceOff) {
				commit('SAVE_TOKEN', {
					loginInfo: responseData,
					checkSaveId: param.checkSaveId,
				});
			}
			return responseData;
		},
		async LOGOUT({ commit }) {
			// 인터셉터에서 토큰 세팅을 해주는데 그전에 초기화되므로 await
			await logout(getUserFromCookie());
			commit('SET_INIT_USER');
		},
		async INIT_PASSWORD({ commit }, param) {
			const { data } = await initPassword(param);
			return data;
		},
		SET_ACCESS_MENUS({ commit }, param) {
			commit('SET_ACCESS_MENUS');
		},
	},

	mutations: {
		SET_USER(state, user) {
			saveUserToCookie(user);
		},
		SET_TOKEN(state, token) {
			saveAuthToCookie(token.accessToken, token.refreshToken);
		},
		SET_ACCESS_TOKEN(state, token) {
			saveAccessTokenToCookie(token);
		},
		SET_INIT_USER(state) {
			deleteCookie('doms_access_token');
			deleteCookie('doms_refresh_token');
			deleteCookie('doms_user');
		},
		SAVE_TOKEN(state, payload) {
			const info = payload.loginInfo;
			const user = {
				id: info.id,
				loginId: info.loginId,
				name: info.name,
			};

			console.log(info);

			this.commit('SET_USER', user);
			this.commit('SET_TOKEN', {
				accessToken: info.token,
				refreshToken: info.refreshToken,
			});
			this.commit('SET_MENUS', info.menus);
			this.commit('SET_ROLES', info.roles);
			if (payload.checkSaveId) {
				saveUserIdToCookie(info.loginId);
			}
		},
		SET_MENUS(state, menus) {
			state.accessMenus = menus;
			localStorage.setItem('accessMenus', encodeURIComponent(JSON.stringify(menus)));
		},
		SET_ROLES(state, roles) {
			state.roles = roles;
			localStorage.setItem('roles', encodeURIComponent(JSON.stringify(roles)));
		},
		SET_ACCESS_MENUS(state) {
			const isReseller = store.state.user.membership.partnerSimpleModel.salesType === 'RESELLER';
			const isInstall = store.getters.isInstall;
			const isDomsAdmin = store.getters.isDomsAdmin;
			const isAccessStore = store.state.user.membership.enableAccessStore;
			const isExceptStore = !isAccessStore || (!isDomsAdmin && (isReseller || isInstall));
			const isExceptOrderHistory = !isAccessStore || (!isDomsAdmin && isReseller);

			let menus = sideMenu;

			if (isExceptStore) {
				menus = menus.filter((menu) => menu.key !== 'store');
			}
			const membershipMenu = _.find(menus, (menu) => menu.key === 'membership') || {};
			if (isExceptOrderHistory) {
				membershipMenu.child = membershipMenu.child.filter((menu) => menu.key !== 'orderHistory');
			}

			if (isInstall) {
				const menu = membershipMenu.child.find((menu) => menu.key === 'orderHistory') || {};
				menu.name = '주문/계약내역';
			}

			state.accessMenus = menus;
		},
	},
};
