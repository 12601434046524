import VueMoment from 'vue-moment';
import VModal from 'vue-js-modal';
import custom from '@/plugins/custom';
import { VTooltip, VPopover } from 'v-tooltip';

import * as utils from '@/utils/utils';
import constants from '@/utils/constants';
import DomsValidateDirective from '@/directives/DomsValidateDirective';
import DomsInputValidateDirective from '@/directives/DomsInputValidateDirective';
import DatePicker from '@/components/datepicker/DatePicker';
import MultiSelectBox from '@/components/code/MultiSelectBox';
import { Multiselect } from 'vue-multiselect';

import { findNameByValue, currency, convertYYMMDD, convertRequiredField, convertUseField } from '@/filter/globalFilter';

export default {
	install(Vue) {
		Vue.prototype.$utils = utils;
		Vue.prototype.$constants = constants;
		Vue.use(VueMoment);
		Vue.use(custom);
		Vue.use(VModal, { dynamic: true });
		Vue.directive('tooltip', VTooltip);
		Vue.component('v-popover', VPopover);
		Vue.component(DatePicker.name, DatePicker);
		Vue.component('multiselect', Multiselect);
		Vue.component(MultiSelectBox.name, MultiSelectBox);
		Vue.use(VTooltip);
		Vue.directive('doms-validate', DomsValidateDirective);
		Vue.directive('doms-inputValidate', DomsInputValidateDirective);
		Vue.filter('findNameByValue', findNameByValue);
		Vue.filter('currency', currency);
		Vue.filter('convertYYMMDD', convertYYMMDD);
		Vue.filter('convertRequiredField', convertRequiredField);
		Vue.filter('convertUseField', convertUseField);
	},
};
