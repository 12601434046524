import Vue from 'vue';
import ConfirmModalTemplate from '../template/ConfirmTemplate.vue';
import AlertModalTemplate from '../template/AlertTemplate.vue';
import HtmlContentTemplate from '../template/HtmlContentTemplate.vue';

var contentInstance;

export const close = () => {
	if (contentInstance) {
		contentInstance.close();
	}
};

export const htmlContentWithOverlay = (title, message, layer) => {
	var ModalConstructor = Vue.extend(HtmlContentTemplate);
	contentInstance = new ModalConstructor({
		el: document.createElement('div'),
		data() {
			return {
				title: title,
				message: message,
				layer: layer,
			};
		},
	});
	contentInstance.isShowModal = true;
	contentInstance.isShowOverlay = true;
	document.body.appendChild(contentInstance.$el);
};

export const confirm = (title, message, callbackSuccess, callbackCancel) => {
	var ModalConstructor = Vue.extend(ConfirmModalTemplate);
	var modalInstance = null;

	if (modalInstance) {
		modalInstance.isShowModal = true;
		modalInstance.isShowOverlay = false;
		modalInstance.title = title;
		modalInstance.message = message;
		return;
	}

	modalInstance = new ModalConstructor({
		el: document.createElement('div'),
		data() {
			return {
				title: title,
				message: message,
			};
		},
		methods: {
			confirm() {
				callbackSuccess();
				modalInstance.isShowModal = false;
			},
			cancel() {
				callbackCancel();
				modalInstance.isShowModal = false;
			},
		},
	});
	modalInstance.isShowModal = true;
	modalInstance.isShowOverlay = false;
	document.body.appendChild(modalInstance.$el);
};

export const confirmWithOverlay = (title, message, callbackSuccess, callbackCancel) => {
	var ModalConstructor = Vue.extend(ConfirmModalTemplate);
	var modalInstance = null;

	if (modalInstance) {
		modalInstance.isShowModal = true;
		modalInstance.isShowOverlay = true;
		modalInstance.title = title;
		modalInstance.message = message;
		return;
	}

	modalInstance = new ModalConstructor({
		el: document.createElement('div'),
		data() {
			return {
				title: title,
				message: message,
			};
		},
		methods: {
			confirm() {
				callbackSuccess();
				modalInstance.isShowModal = false;
			},
			cancel() {
				callbackCancel();
				modalInstance.isShowModal = false;
			},
		},
	});
	modalInstance.isShowModal = true;
	modalInstance.isShowOverlay = true;
	document.body.appendChild(modalInstance.$el);
};

export const alert = (title, message, callback) => {
	var ModalConstructor = Vue.extend(AlertModalTemplate);
	var modalInstance = null;

	if (modalInstance) {
		modalInstance.isShowModal = true;
		modalInstance.title = title;
		modalInstance.message = message;
		return;
	}

	modalInstance = new ModalConstructor({
		el: document.createElement('div'),
		data() {
			return {
				title: title,
				message: message,
			};
		},
		methods: {
			close() {
				if ('function' == typeof callback) {
					callback();
				}
				modalInstance.isShowModal = false;
			},
		},
	});
	modalInstance.isShowModal = true;
	document.body.appendChild(modalInstance.$el);
};

export const alertWithOverlay = (title, message, callback) => {
	var ModalConstructor = Vue.extend(AlertModalTemplate);
	var modalInstance = null;

	if (modalInstance) {
		modalInstance.isShowModal = true;
		modalInstance.isShowOverlay = true;
		modalInstance.title = title;
		modalInstance.message = message;
		return;
	}

	modalInstance = new ModalConstructor({
		el: document.createElement('div'),
		data() {
			return {
				title: title,
				message: message,
			};
		},
		methods: {
			close() {
				if ('function' == typeof callback) {
					callback();
				}
				modalInstance.isShowModal = false;
			},
		},
	});
	modalInstance.isShowModal = true;
	modalInstance.isShowOverlay = true;
	document.body.appendChild(modalInstance.$el);
};
