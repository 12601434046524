import { show } from './toast';

/**
 * @param {Object[]} validateModels
 * @param {Object} validateModels[].data - vue의 data
 * @param {string} validateModels[].name - 데이터명
 * @param {string} validateModels[].validateType - 벨리데이션 타입 ( required,min .. )
 */
export const dataValidate = async (validateModels) => {
	//    [{
	//       data : module,
	//       name : '데이터 명'
	//       validateType : 'required'  or 'required|min:2  or 'required|number'
	//    [{
	return new Promise((resolve, reject) => {
		let resultError = false;
		for (const validate of validateModels) {
			const functions = validate.validateType;
			functions.split('|').some((func) => {
				var validateFunDetail = func.split(':');
				const result = validateFns[validateFunDetail[0]](validate.name, validate.data, validateFunDetail[1]);
				if (result != undefined) {
					resultError = true;
					show(result);
					return true;
				}
			});
			if (resultError) {
				break;
			}
		}
		resultError ? reject(resultError) : resolve(resultError);
	});
};

const validateFns = {
	required(key, val) {
		if (val !== 0 && (!val || !val.toString().length)) {
			return `${key}는(은) 필수값입니다.`;
		}
	},
	min(key, val, len) {
		if (!val || val.length < len) {
			return `${key}는(은) 최소 ${len}자 이상입니다.`;
		}
	},
	max(key, val, len) {
		if (val != '' && val.length > len) {
			return `${key}는(은) 최대 ${len}자를 초과할 수 없습니다.`;
		}
	},
	number(key, val) {
		if (isNaN(val)) {
			return `${key}는(은) 숫자만 입력할 수 있습니다.`;
		}
	},
	numberNotAllowBlank(key, val) {
		if (val.toString() == '') {
			return `${key}는(은) 숫자를 입력해주셔야 합니다.`;
		}
	},
	requiredDate(key, val) {
		if (val == '' || val == undefined) {
			return `${key}에 달력을 선택해주세요.`;
		}
	},
	requiredCalendarDateRange(key, val) {
		if (val <= 0 || val > 31) {
			return `${key} 범위는 1~31만 가능합니다.`;
		}
	},
	email(key, val) {
		if (!checkEmail(val)) {
			return `${key}는(은) 이메일 형식에 맞지 않습니다.`;
		}
	},
	requiredCheck(key, val) {
		if (!val) {
			return `${key}는 필수 입니다.`;
		}
	},
};

const checkEmail = (email) => {
	// 이메일 형식 체크
	if (email.length < 2 || email.length > 255) {
		return false;
	}
	if (email.indexOf('%') > -1) {
		return false;
	}
	if (email.indexOf('&') > -1) {
		return false;
	}
	if (email.indexOf('\\') > -1) {
		return false;
	}

	var dataArr = email.split('@');
	if (dataArr.length != 2) {
		return false;
	}
	// dataArr[0] : 로컬파트, dataArr[1] :도메인
	// 로컬 파트
	var local = dataArr[0];
	var localPattern = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
	if (localPattern.test(local)) {
		return false;
	}
	// 도메인
	var domain = dataArr[1];
	var domainPattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

	if (domainPattern.test(domain.replace(/[\\.-]/g, ''))) {
		return false;
	}

	const regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

	if (email.match(regExp) != null) {
		return true;
	} else {
		return false;
	}
};
