<template>
	<transition name="modal">
		<div class="modal-mask" v-if="isShowModal">
			<div class="overlay" v-if="isShowOverlay"></div>
			<div :class="['com_layer_normal layer_' + layer]" v-bind:style="calculateStyle">
				<div class="lay_header">
					<h1>{{ title }}</h1>
					<a class="btn_close" title="닫기" @click="close"><span class="ic_set ic_close"></span></a>
				</div>
				<!--content-->
				<div class="lay_body" v-html="getContent()"></div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			isShowModal: false,
			isShowOverlay: false,
			title: '',
			message: '',
			layer: '',
		};
	},
	computed: {
		calculateStyle() {
			const winWidth = '640px';
			const layerLeft = winWidth / 2 - 330;
			const layerTop = (window.scrollY || document.documentElement.scrollTop) - 150;
			return `left:${layerLeft}px;top:${layerTop + 200}px;z-index:100;`;
		},
	},
	methods: {
		show(showOverlay, title, message, layer) {
			this.isShowModal = true;
			this.isShowOverlay = showOverlay;
			this.title = title;
			this.message = message;
			this.layer = layer;
		},
		close() {
			this.isShowModal = false;
			this.isShowOverlay = false;
		},
		getContent() {
			let el = new DOMParser().parseFromString(this.message, 'text/html');
			let imgs = el.getElementsByTagName('img');
			if (imgs) {
				Array.prototype.forEach.call(imgs, (img) => {
					img.src = require('@/assets/' + img.getAttribute('path'));
				});
			}
			let body = el.body;
			return body.innerHTML;
		},
	},
};
</script>
