import {
	searchOrders,
	getOrderInfo,
	getContractInfoByCode,
	createNewOrderInfo,
	applyNewOrderInfo,
	getNewOrderModel,
	sendNewOrderEstimate,
	confirmAdminId,
	getMembershipPaymentDetail,
	getMembershipBaseChannelChanged,
	getConversionMembership,
	calculateItemPrice,
} from '@/api/order';

import { getBaseChannelAddExtensionOptionItemList, getAddonChannelExtensionOptionItemList } from '@/api/product';

export default {
	state: {
		orderTargetCustomer: {},
		newOrder: {},
	},
	getters: {
		getOrderTargetCustomer(state) {
			return state.orderTargetCustomer;
		},
	},
	actions: {
		async GET_CONTRACT_INFO({ commit }, params) {
			// 계약 정보 조회 (params : 계약코드(주문코드+'-CONT'))
			const { data } = await getContractInfoByCode(params);
			return data;
		},
		async SEARCHORDERS({ commit }, params) {
			// 주문 내역 조회 (params : membershipId)
			const { data } = await searchOrders(params.pageInfo);
			return data;
		},
		async GETORDERINFO({ commit }, params) {
			// 주문 상세 조회 (params : orderIdo)
			const data = await getOrderInfo(params.id);
			return data;
		},
		async GETNEWORDERMODEL({ commit }, params) {
			const { data } = await getNewOrderModel(params);
			return data;
		},
		async CREATENEWORDERINFO({ commit }, params) {
			const { data } = await createNewOrderInfo(params);
			return data;
		},
		async APPLYNEWORDERINFO({ commit }, params) {
			await applyNewOrderInfo(params);
		},
		async SENDNEWORDERESTIMATE({ commit }, params) {
			const { data } = await sendNewOrderEstimate(params);
			return data;
		},
		async GET_BASECHANNEL_ADD_EXTENSION_OPTIONITEM_LIST({ commit }, params) {
			const { data } = await getBaseChannelAddExtensionOptionItemList(params);
			return data;
		},
		async GET_ADDONCHANNEL_EXTENSION_OPTIONITEM_LIST({ commit }, params) {
			const { data } = await getAddonChannelExtensionOptionItemList(params);
			return data;
		},
		async CONFIRM_ADMIN_ID({ commit }, params) {
			const { data } = await confirmAdminId(params);
			return data;
		},
		async GET_MEMBERSHIP_PAYMENT_DETAIL({ commit }, params) {
			const { data } = await getMembershipPaymentDetail(params);
			return data;
		},

		async GET_MEMBERSHIP_BASE_CHANNEL_CHANGED({ commit }, params) {
			const { data } = await getMembershipBaseChannelChanged(params);
			return data;
		},
		async GET_CONVERSION_MEMBERSHIP({ commit }, params) {
			const { data } = await getConversionMembership(params);
			return data;
		},
		async CALCULATE_ITEM_PRICE({ commit }, params) {
			const { data } = await calculateItemPrice(params);
			return data;
		},
	},
	mutations: {
		setOrderTargetCustomer(state, customer) {
			state.orderTargetCustomer = customer;
		},
	},
};
