import Vue from 'vue';

import VueRouter from 'vue-router';

import store from '../store/index';

Vue.use(VueRouter);

const saveCurrentStep = (type) => (to, from, next) => {
	const payload = {
		type: type,
		step: to.name.toLowerCase(),
	};
	store.commit('setCurrentStep', payload);
	next();
};

const callGTM = () => {
	let gtm = document.createElement('script');
	gtm.async = true;
	gtm.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-2YS189R163');
	document.head.appendChild(gtm);

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push('js', new Date());
	window.dataLayer.push('config', 'G-2YS189R163');
};

const routes = [
	{
		path: '',
		name: '',
		beforeEnter(to, from, next) {
			// Put the full page URL including the protocol http(s) below
			window.location.replace('https://www.daouoffice.com');
		},
	},
	{
		path: '/',
		name: '/',
		beforeEnter(to, from, next) {
			// Put the full page URL including the protocol http(s) below
			window.location.replace('https://www.daouoffice.com');
		},
	},
	{
		path: '/pay',
		name: 'KiwoomPay',
		component: () => import('@/views/payment/Kiwoompay.vue'),
	},
	{
		path: '/kiwoom/popup',
		name: 'KiwoomPayPopup',
		component: () => import('@/components/payment/kiwoompay/PayRequest.vue'),
	},
	{
		path: '/apply',
		name: 'Apply',
		component: () => import('@/views/apply/Apply.vue'),
		redirect: { name: 'step1' },
		children: [
			{
				path: 'step1',
				name: 'step1',
				beforeEnter(to, from, next) {
					saveCurrentStep('apply');
					callGTM();
					next();
				},

				component: () => import('@/views/apply/ApplyService.vue'),
			},
			{
				path: 'step1/free-direct',
				name: 'free-direct',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/ApplyService.vue'),
			},
			{
				path: 'service/step1',
				name: 'ServiceStep1',
				beforeEnter(to, from, next) {
					saveCurrentStep('apply');
					callGTM();
					next();
				},
				component: () => import('@/views/apply/service/Step1.vue'),
			},
			{
				path: 'service/step2',
				name: 'ServiceStep2',
				beforeEnter(to, from, next) {
					saveCurrentStep('apply');
					callGTM();
					next();
				},
				component: () => import('@/views/apply/service/Step2.vue'),
			},
			{
				path: 'service/step3',
				name: 'ServiceStep3',
				beforeEnter(to, from, next) {
					saveCurrentStep('apply');
					callGTM();
					next();
				},
				component: () => import('@/views/apply/service/Step3.vue'),
			},
			{
				path: 'estimate/step1',
				name: 'EstimateStep1',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/estimate/Step1.vue'),
			},
			{
				path: 'estimate/step2',
				name: 'EstimateStep2',
				beforeEnter: saveCurrentStep('apply'),
				component: () => import('@/views/apply/estimate/Step2.vue'),
			},
		],
	},
	{
		path: '/user',
		name: 'User',
		component: () => import('@/views/user/User.vue'),
		children: [
			{
				path: 'myStore/MembershipStatus',
				name: 'MembershipStatus',
				meta: { upperCrumb: '멤버십', breadCrumb: '가입현황' },
				beforeEnter: saveCurrentStep('user'),
				component: () => import('@/views/user/myStore/MembershipStatus.vue'),
			},
			{
				path: 'myStore/CompanyInfo',
				name: 'CompanyInfo',
				meta: { upperCrumb: '멤버십', breadCrumb: '회사정보' },
				beforeEnter: saveCurrentStep('user'),
				component: () => import('@/views/user/myStore/CompanyInfo.vue'),
			},
			{
				path: 'myStore/SiteStatus',
				name: 'SiteStatus',
				meta: { upperCrumb: '멤버십', breadCrumb: '사이트현황' },
				component: () => import('@/views/user/myStore/SiteStatus.vue'),
			},
			{
				path: 'myStore/OrderHistory',
				name: 'OrderHistory',
				meta: { upperCrumb: '멤버십', breadCrumb: '주문/구매내역' },
				component: () => import('@/views/user/myStore/OrderHistory.vue'),
			},
			{
				path: 'myStore/OrderDetail/:id',
				name: 'OrderDetail',
				meta: { upperCrumb: '멤버십', breadCrumb: '주문/구매내역 상세' },
				component: () => import('@/views/user/myStore/OrderDetail.vue'),
			},
		],
	},
	{
		path: '/order',
		name: 'Order',
		component: () => import('@/views/order/Order.vue'),
		redirect: { name: 'order/AddExtension' },
		children: [
			{
				path: 'addExtension',
				name: 'OrderAddExtension',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장',
				},
				beforeEnter: saveCurrentStep('order'),
				component: () => import('@/views/order/AddExtension.vue'),
			},
			{
				path: 'addExtension/autoExtension',
				name: 'OrderAddExtensionAutoExtension',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장',
				},
				component: () => import('@/views/order/AddExtension.vue'),
			},
			{
				path: 'purchase',
				name: 'OrderPurchase',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장 구매하기',
				},
				beforeEnter: saveCurrentStep('order'),
				component: () => import('@/views/order/Purchase.vue'),
			},
			{
				path: 'complete',
				name: 'OrderComplete',
				meta: {
					upperCrumb: '스토어',
					breadCrumb: '서비스 추가·연장 구매완료',
				},
				beforeEnter: saveCurrentStep('order'),
				component: () => import('@/views/order/Complete.vue'),
			},
		],
	},
	{
		path: '*',
		component: () => import('@/views/common/NotFound.vue'),
	},
	{
		path: '/underconstruct',
		component: () => import('@/views/common/UnderConstruction.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,
});

router.beforeEach((to, from, next) => {
	if (to.path != '/underconstruct' && window.__env.underConstruction.apply) {
		next({
			path: 'underconstruct',
			replace: true,
		});
	} else {
		next();
	}
});
export default router;
